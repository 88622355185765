import { connect } from 'react-redux';
import {
    MyAccountDispatcher as SourceMyAccountDispatcher,
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { Dispatch } from 'redux';
import MyAccountQuery from 'Query/MyAccount.query';
import { ORDER_ID } from 'Component/MyAccountOrder/MyAccountOrder.config';
import { deleteAuthorizationToken, getAuthorizationToken, setAuthorizationToken } from 'Util/Auth/Token';
import agtm from '../../gtm/tags';
import { CONFIRMATION_REQUIRED } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import { AccountPageUrl, CUSTOMER } from 'Route/MyAccount/MyAccount.config';
import {
    updateCustomerDetails,
    updateCustomerPasswordForgotStatus,
    updateCustomerPasswordResetStatus,
    updateCustomerSignInStatus,
    updateIsLoading,
    updateIsLocked,
} from 'SourceStore/MyAccount/MyAccount.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { deleteCartId, getCartId, setCartId } from 'Util/Cart';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import { NotificationType, ShowNotificationAction } from 'SourceStore/Notification/Notification.type';
import { prepareQuery } from 'Util/Query';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/Config';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import { executePost } from 'Util/Request/Request';
import BrowserDatabase from 'Util/BrowserDatabase';
import WishlistDispatcher from 'Store/Wishlist/Wishlist.dispatcher';
import ProductCompareDispatcher from 'Store/ProductCompare/ProductCompare.dispatcher';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace AlmusbahblendPwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    // TODO implement logic

    /**
     * Sign in action
     * @param {{email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    async signIn(options, dispatch) {

        const mutation = MyAccountQuery.getSignInMutation(options || {});

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);

        ProductCompareDispatcher.assignCompareList(dispatch);

        const guestCartToken = getCartId() || '';
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await CartDispatcher.createGuestEmptyCart(dispatch) || '';

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            CartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setCartId(customerCartToken);
        CartDispatcher.updateInitialCartData(dispatch, true);

        WishlistDispatcher.updateInitialWishlistData(dispatch);

        await this.requestCustomerData(dispatch);

        // Call GTM for customer login
        const currentCustomer = BrowserDatabase.getItem(CUSTOMER);
        // console.log("Customer after login currentCustomer:", currentCustomer);
        
        const weUser = {
            email: currentCustomer.email, 
            firstname: currentCustomer.firstname, 
            lastname: currentCustomer.lastname
        }

        let storeCode = appendWithStoreCode('');
        storeCode = (storeCode.startsWith('/') ? storeCode.slice(1) : storeCode);
        // console.log("Storecode in HyperPay: ", storeCode);

        // console.log("currentStore: ", storeCode);
        weUser['Language'] = storeCode === 'ar-sa' ? 'Arabic' : 'English';
		weUser['Registered_on'] = new Date();

        agtm.triggerAuthDLEvent('WeSignIn', weUser);


        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification(NotificationType.SUCCESS, __('You are successfully logged in!')));

        return true;
    }

    /**
     * Create account action
     * @param {{customer: Object, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    async createAccount(options, dispatch) {
        const { customer: { email = '' }, password } = options || {};
        const mutation = MyAccountQuery.getCreateAccountMutation(options);

        dispatch(updateIsLoading(true));

        try {
            const data = await fetchMutation(mutation);
            const { createCustomer: { customer } } = data;
            const { confirmation_required } = customer;

            const weUser = {
                email: customer.email, 
                firstname: customer.firstname, 
                lastname: customer.lastname
            }

            let storeCode = appendWithStoreCode('');
            storeCode = (storeCode.startsWith('/') ? storeCode.slice(1) : storeCode);
            // console.log("Storecode in HyperPay: ", storeCode);

            // console.log("currentStore: ", storeCode);
            weUser['Language'] = storeCode === 'ar-sa' ? 'Arabic' : 'English';
            weUser['Registered_on'] = new Date();

            // Call GTM for new customer 
            // console.log("Customer after create account:", customer);
            agtm.triggerAuthDLEvent('WeSignup', weUser);

            sessionStorage.setItem(ORDER_ID, '');

            if (confirmation_required) {
                dispatch(updateIsLoading(false));

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return CONFIRMATION_REQUIRED;
                }

                // return await this.signIn({ email, password }, dispatch);
            }
            return true;
            // return await this.signIn({ email, password }, dispatch);
        } catch (error) {
            dispatch(updateIsLoading(false));
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));

            return false;
        }
    }

}

export default new MyAccountDispatcher();
