import {
    ProductCompareDispatcher as SourceProductCompareDispatcher,
} from 'SourceStore/ProductCompare/ProductCompare.dispatcher';

/** @namespace AlmusbahblendPwa/Store/ProductCompare/Dispatcher */
export class ProductCompareDispatcher extends SourceProductCompareDispatcher {
    // TODO implement logic
}

export default new ProductCompareDispatcher();
